<template>
  <el-card style="margin-top:0px">
    <el-form :model="chukuForm" :rules="rules" ref="form" label-width="100px" size="small">
      <el-form-item>
        <el-radio-group v-model="bendiKufang" @change="setBendiKufang">
          <el-radio label="1">本分公司库房出库</el-radio>
          <el-radio label="2">其他分公司库房出库</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="项目或工地" prop="contract" :rules="{ required: true, message: '请输入合同编号或工地', trigger: 'blur' }">
            <el-select
              v-model="chukuForm.contract"
              filterable
              remote
              placeholder="项目或工地"
              :remote-method="remoteMethod"
              :loading="loading"
              @change="getCailiaotzdOrDiaobodans"
            >
              <el-option v-for="item in XiangmuGongdiOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="出库时间" prop="date">
            <el-date-picker
              type="date"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              v-model="chukuForm.date"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-form-item label="出库数量" prop="amount">
      <el-select v-model="chukuForm.cailiaotongzhidan" placeholder="请选择材料名称">
        <el-option v-for="item in cailiaotongzhidans" :key="item" :label="item" :value="item"></el-option> </el-select
      ><el-input v-model.number="chukuForm.amount"></el-input>
    </el-form-item> -->
      <div v-if="bendiKufang === '1'">
        <el-row v-for="(tongzhidan, index) in chukuForm.cailiaotongzhidans" :key="tongzhidan.key">
          <el-col :span="24">
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="材料通知单"
                  :prop="'cailiaotongzhidans.' + index + '.tongzhidan_id'"
                  :rules="{ required: true, message: '请选择材料通知单', trigger: 'blur' }"
                >
                  <el-select v-model="tongzhidan.tongzhidan_id" placeholder="请选择材料通知单" @change="selTongzhidan($event, index)">
                    <el-option v-for="item in cailiaotongzhidans_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="出库数量"
                  :prop="'cailiaotongzhidans.' + index + '.out_amount'"
                  :rules="
                    ({ required: true, message: '请输入数量', trigger: 'blur' },
                    { type: 'number', message: '数量必须为数字' },
                    {
                      validator: (rule, value, callback) => {
                        validateLeftamount(rule, value, callback, tongzhidan.left_amount)
                      },
                      trigger: 'blur'
                    })
                  "
                >
                  <el-input v-model.number="tongzhidan.out_amount"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-button size="small" @click.prevent="removeDomainCailiaotzd(tongzhidan)">删除</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item>
                  <span style="color:blue">{{ msg_tongzhidan[index] }} </span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button @click="addDomainCailiaotzd" type="success" plain>选择另一个材料通知单</el-button>
        </el-form-item>
      </div>
      <div v-if="bendiKufang === '2'">
        <el-row v-for="(diaobodan, index) in chukuForm.diaobodans" :key="diaobodan.key">
          <el-col :span="24">
            <el-row>
              <el-col :span="10">
                <el-form-item
                  label="调拨单"
                  :prop="'diaobodans.' + index + '.diaobodan_id'"
                  :rules="{ required: true, message: '请选择调拨单', trigger: 'blur' }"
                >
                  <el-select v-model="diaobodan.diaobodan_id" placeholder="请选择调拨单" @change="selDiaobodan($event, index)">
                    <el-option v-for="item in diaobodans_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label="出库数量"
                  :prop="'diaobodans.' + index + '.out_amount'"
                  :rules="
                    ({ required: true, message: '请输入数量', trigger: 'blur' },
                    { type: 'number', message: '数量必须为数字' },
                    {
                      validator: (rule, value, callback) => {
                        validateLeftamount(rule, value, callback, diaobodan.left_amount)
                      },
                      trigger: 'blur'
                    })
                  "
                >
                  <el-input v-model.number="diaobodan.out_amount"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-button size="small" @click.prevent="removeDomainDiaobodan(diaobodan)">删除</el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item>
                  <span style="color:blue">{{ msg_diaobodan[index] }} </span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button @click="addDomainDiaobodan" type="warning" plain>选择另一个调拨单</el-button>
        </el-form-item>
      </div>

      <el-form-item label="上传图片：" prop="photos">
        <el-upload
          class="upload-demo"
          ref="upload"
          action="http://ln-jz.cn:8000/uploadImg/"
          multiple
          accept="image/png, image/jpeg,image/gif"
          list-type="picture"
          :on-remove="handleRemove"
          :file-list="fileList"
          :before-upload="beforeUploadPicture"
          :on-success="uploadSuccess"
          :auto-upload="false"
        >
          <div slot="tip" class="el-upload__tip">只能上传jpg/png/gif文件，且单个文件不超过10M</div>
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
          <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="出库备注" prop="remark">
        <el-input type="textarea" rows="3" v-model="chukuForm.remark"></el-input>
      </el-form-item>
      <el-form-item>
        <div style="float:right;">
          <el-button @click="resetForm('form')"> 取消 </el-button>
          <el-button type="primary" @click="submitForm('form')"> 确认添加 </el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import utils from '@/assets/js/utils'

export default {
  props: {
    cailiaotongzhidans: Array
  },
  data() {
    return {
      bendiKufang: '1',
      XiangmuGongdiOptions: [],
      company: utils.getCompanyCookie(),
      cangku_code: '',
      fileList: [],
      loading: false,
      chukuForm: {
        cailiaotongzhidans: [
          {
            tongzhidan_id: '',
            out_amount: '',
            cangku_id: '',
            cangku_name: '',
            cailiao_id: '',
            cailiao_name: '',
            cailiaoguige_id: '',
            cailiaoguige_name: ''
          }
        ],
        diaobodans: [
          {
            diaobodan_id: '',
            out_amount: '',
            cangku_id: '',
            cangku_name: '',
            cailiao_id: '',
            cailiao_name: '',
            cailiaoguige_id: '',
            cailiaoguige_name: ''
          }
        ],
        remark: '' //设置默认值为空字符，以解决该项没有输入时后台requestdata['remark']key值为空的问题。
      },
      msg_tongzhidan: [],
      msg_diaobodan: [],

      isValidationOk: false,
      cangkus_options: [],
      cailiaos_options: [],
      cailiaoguiges_options: [],
      cailiaotongzhidans_options: [],
      diaobodans_options: [],
      file_count: 0,

      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        cangku: [{ required: true, message: '请选择所属仓库', trigger: 'blur' }],
        cailiao: [{ required: true, message: '请选择材料名称', trigger: 'blur' }],
        cailiaoguige: [{ required: true, message: '请选择材料规格', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入数量', trigger: 'blur' }, { type: 'number', message: '数量必须为数字' }],
        date: [{ required: true, message: '请选择调配时间', trigger: 'blur' }]
      }
    }
  },
  methods: {
    validateLeftamount(rule, value, callback, left_amount) {
      if (value > left_amount) {
        callback(new Error('出库数量超出未发数量！'))
      } else {
        callback()
      }
    },
    //选择本地库房出库还是非本地库房出库
    setBendiKufang(label) {
      this.bendiKufang = label
      console.log('this.bendikufang:', this.bendiKufang)
      // this.getCangkus()
      this.getCailiaotzdOrDiaobodans()
    },

    //项目工地搜索框响应事件，从后台查询10条记录
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          const api = '/getXiangmuGongdis/'
          this.$axios
            .get(api, {
              params: {
                query: query
              }
            })
            .then(res => {
              if (res.data.code === 1000) {
                this.XiangmuGongdiOptions = res.data.data.map(item => {
                  item.label = item.contract_id + '#' + item.project
                  item.value = item.id
                  return item
                })
              } else {
                console.log('没有项目工地信息！')
              }

              console.log('XiangmuGongdiOptions', this.XiangmuGongdiOptions)
            })
        }, 200)
      } else {
        this.XiangmuGongdiOptions = []
      }
    },

    //根据选择的项目工地（合同编号）取得该合同的所有材料通知单
    getCailiaotongzhidans() {
      const api = '/getCailiaotongzhidanByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.chukuForm.contract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            this.cailiaotongzhidans_options = res.data.data.map(item => {
              item.label = item.code
              item.value = item.id
              return item
            })
          } else {
            console.log('没有该合同对应的材料通知单信息！')
            this.cailiaotongzhidans_options = []
          }

          console.log('cailiaotongzhidans_options', this.cailiaotongzhidans_options)
        })
    },

    //材料通知单选择处理
    selTongzhidan(e, index) {
      let tongzhidan_id = e
      let form_index = index
      const api = '/getCailiaotongzhidanInfo/'
      this.$axios
        .get(api, {
          params: {
            id: tongzhidan_id
          }
        })
        .then(res => {
          if (res.data.code === '1000') {
            console.log('get cailiaotongzhidan info:', res.data.cangku_name)
            this.chukuForm.cailiaotongzhidans[form_index].cangku_id = res.data.cangku_id
            this.chukuForm.cailiaotongzhidans[form_index].cailiao_id = res.data.cailiao_id
            this.chukuForm.cailiaotongzhidans[form_index].cailiaoguige_id = res.data.cailiaoguige_id
            this.chukuForm.cailiaotongzhidans[form_index].left_amount = res.data.left_amount
            this.$set(
              this.msg_tongzhidan,
              form_index,
              '[出库库房: ' +
                res.data.cangku_name +
                '] -- [材料: ' +
                res.data.cailiao_name +
                '-' +
                res.data.cailiaoguige_name +
                '] -- [未出库数量: ' +
                res.data.left_amount +
                ']'
            )

            console.log('msg_tongzhidan:', this.msg_tongzhidan)
          } else {
            console.log('没有该材料通知单信息！')
          }
        })
      console.log('index of cailiaotongzhidan:', form_index)
      console.log('cailiaotongzhidan select:', tongzhidan_id)
      console.log('chukuform:', this.chukuForm)
    },

    //根据选择的项目工地（合同编号）取得该合同的所有调拨单
    getDiaobodans() {
      const api = '/getDiaobodanByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.chukuForm.contract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            this.diaobodans_options = res.data.data.map(item => {
              item.label = item.code
              item.value = item.id + '-' + item.cailiaotongzhidan
              console.log('cailiaotongzhidan id:', item.value)
              return item
            })
          } else {
            console.log('没有该合同对应的调拨单信息！')
            this.diaobodans_options = []
          }

          console.log('diaobodans_options', this.diaobodans_options)
        })
    },

    //项目合同输入选择框响应事件，根据是否是本地库房出库获取材料通知单或调拨单
    getCailiaotzdOrDiaobodans() {
      console.log('contract..........:', this.chukuForm.contract)
      if (this.bendiKufang === '1') {
        this.getCailiaotongzhidans()
      } else {
        this.getDiaobodans()
      }
    },

    //增加材料通知单表单域
    addDomainCailiaotzd() {
      this.chukuForm.cailiaotongzhidans.push({
        tongzhidan_id: '',
        out_amount: '',
        key: Date.now()
      })
    },

    //删除材料通知单表单域
    removeDomainCailiaotzd(tongzhidan) {
      var index = this.chukuForm.cailiaotongzhidans.indexOf(tongzhidan)
      if (index !== -1) {
        this.chukuForm.cailiaotongzhidans.splice(index, 1)
      }
    },

    //增加调拨单表单域
    addDomainDiaobodan() {
      this.chukuForm.diaobodans.push({
        diaobodan_id: '',
        out_amount: '',
        key: Date.now()
      })
    },

    //删除调拨单表单域
    removeDomainDiaobodan(diaobodan) {
      var index = this.chukuForm.diaobodans.indexOf(diaobodan)
      if (index !== -1) {
        this.chukuForm.diaobodans.splice(index, 1)
      }
    },
    //调拨单选择处理
    selDiaobodan(e, index) {
      let diaobodan_id = e
      let form_index = index
      const api = '/getDiaobodanInfo/'
      this.$axios
        .get(api, {
          params: {
            id: diaobodan_id
          }
        })
        .then(res => {
          if (res.data.code === '1000') {
            console.log('get diaobodan info:', res.data.cangku_name)
            this.chukuForm.diaobodans[form_index].cangku_id = res.data.cangku_id
            this.chukuForm.diaobodans[form_index].cailiao_id = res.data.cailiao_id
            this.chukuForm.diaobodans[form_index].cailiaoguige_id = res.data.cailiaoguige_id
            this.chukuForm.diaobodans[form_index].left_amount = res.data.left_amount
            this.$set(
              this.msg_diaobodan,
              form_index,
              '[出库库房: ' +
                res.data.cangku_name +
                '] -- [材料: ' +
                res.data.cailiao_name +
                '-' +
                res.data.cailiaoguige_name +
                '] -- [未出库数量: ' +
                res.data.left_amount +
                ']'
            )

            console.log('msg_diaobodan:', this.msg_diaobodan)
          } else {
            console.log('没有该调拨单信息！')
          }
        })
      console.log('index of diaobodan:', form_index)
      console.log('diaobodan select:', diaobodan_id)
      console.log('chukuform:', this.chukuForm)
    },
    // getCangkus() {
    //   this.cangkus_options = []
    //   if (this.bendiKufang === '1') this.cangku_code = this.company
    //   else this.cangku_code = 'all'
    //   console.log('cangku_code:', this.cangku_code)
    //   this.$axios
    //     .get('/getCangkus/', {
    //       params: {
    //         cangku_code: this.cangku_code
    //       }
    //     })
    //     .then(res => {
    //       console.log('getCangkus list:', res.data)
    //       res.data.forEach(element => {
    //         let cangku = {}
    //         cangku.label = String(element.name)
    //         cangku.value = element.id
    //         this.cangkus_options.push(cangku)
    //       })
    //       console.log('cangkus_options', this.cangkus_options)
    //     })
    // },
    // getCailiaos() {
    //   this.$axios.get('/getCailiaos/').then(res => {
    //     console.log('getCailiaos list:', res.data)
    //     res.data.forEach(element => {
    //       let cailiao = {}
    //       cailiao.label = String(element.name)
    //       cailiao.value = element.id
    //       this.cailiaos_options.push(cailiao)
    //     })
    //     console.log('cailiaos_options', this.cailiaos_options)
    //   })
    // },
    // getCailiaoguiges() {
    //   this.$axios.get('/getCailiaoguiges/').then(res => {
    //     console.log('getCailiaoguiges list:', res.data)
    //     res.data.forEach(element => {
    //       let cailiaoguige = {}
    //       cailiaoguige.label = String(element.name)
    //       cailiaoguige.value = element.id
    //       this.cailiaoguiges_options.push(cailiaoguige)
    //     })
    //     console.log('cailiaoguiges_options', this.cailiaoguiges_options)
    //   })
    // },

    // 上传图片前调用方法
    beforeUploadPicture(file) {
      if (file.size > 10 * 1024 * 1024) {
        this.$message.error('上传图片不能大于10M')
        return false
      }
    },
    // 上传图片成功
    uploadSuccess(res, file, fileList) {
      this.file_count += 1
      this.fileChange(fileList)
      console.log('uploadfilelist.len:', fileList.length)
      console.log('file_count:', this.file_count)
    },
    // 移除图片
    handleRemove(file, fileList) {
      console.log('RemovefileList', fileList)
      this.fileChange(fileList)
    },
    // 设置photo字段值
    fileChange(fileList) {
      let temp_str = ''
      this.file_count = fileList.length
      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          if (i === 0) {
            //列表中的第一个,作为temp_str的第一个元素
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += '/api/' + fileList[i].response.data //本地
                temp_str += fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += fileList[i].url
            }
          } else {
            //不是列表中的第一个,插入到temp_str中
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += ',' + '/api/' + fileList[i].response.data //本地
                temp_str += ',' + fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += ',' + fileList[i].url
            }
          }
        }
      }
      this.chukuForm.photos = temp_str
      console.log('photos:', this.chukuForm.photos)
    },
    submitUpload() {
      this.$refs.upload.submit()
    },

    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.file_count < 1) {
            console.log('fileList_len:', this.fileList.length)
            this.$message({
              type: 'alert',
              message: '请至少上传1张图片!',
              duration: 2000
            })
          } else {
            console.log('validation ok!')
            console.log('this.contract........:', this.chukuForm.contract)
            this.chukuForm.bendikufang = this.bendiKufang
            console.log('Form组件中的chukuForm.contract：', this.chukuForm.contract)
            console.log('Form组件中的chukuForm：', this.chukuForm)
            this.$emit('submitForm', this.chukuForm)
            this.ClearForm()
          }
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },

    //清空表单
    ClearForm() {
      this.chukuForm = {
        cailiaotongzhidans: [
          {
            tongzhidan_id: '',
            out_amount: ''
          }
        ],
        diaobodans: [
          {
            diaobodan_id: '',
            out_amount: ''
          }
        ]
      }
      this.bendiKufang = '1'
      this.fileList = []
      this.file_count = 0
      this.cailiaotongzhidans_options = []
      this.diaobodans_options = []
    },

    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.ClearForm()
      this.$emit('resetForm')
    },

    initForm() {
      // this.getCangkus(), this.getCailiaos(), this.getCailiaoguiges()
    }
  },

  created() {
    // this.initForm()
  }
}
</script>

<style lang="scss" scoped></style>
